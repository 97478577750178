import { Box, Typography } from "@mui/material/";

const PageNotFound = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "calc(100vh - 45px)" }}
    >
      <Box sx={{ textAlign: "center", opacity: 0.6 }}>
        <Typography variant="h6" sx={{ fontSize: 80 }}>
          404
        </Typography>
        <Typography variant="h6" sx={{ fontSize: 50 }}>
          Page Not Found!
        </Typography>
      </Box>
    </Box>
  );
};

export default PageNotFound;
