import { Zoom, Button, Stack, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(Box)({
  position: "absolute",
  width: 300,
  borderRadius: 3,
  backgroundColor: "#fff",
  top: 50,
  right: 16,
  padding: "12px 8px",
  boxShadow: "0px 0px 20px 5px rgba(0,0,0,0.5)",
  zIndex: 999,
});

const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  width: "90%",
  height: "100%",
});

const Popup = ({ open, companyName, handleSurveyMode, handleDrawer }) => {
  return (
    <Zoom in={open}>
      <Container>
        <Wrapper>
          <Typography align="center" gutterBottom variant="h6">
            Do you know <b>{companyName}</b> ?
          </Typography>
          <Box mx={1}>
            <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
              <Button
                fullWidth
                size="small"
                color="button"
                variant="contained"
                onClick={() => {
                  handleDrawer();
                  handleSurveyMode(true);
                }}
              >
                Yes
              </Button>
              <Button
                fullWidth
                size="small"
                color="button"
                variant="contained"
                onClick={handleDrawer}
              >
                No
              </Button>
            </Stack>
            <Box mt={2} sx={{ lineHeight: 1 }}>
              <Typography variant="caption" sx={{ opacity: 0.75 }}>
                Help us know more about this company, by answering few
                questions.
              </Typography>
            </Box>
          </Box>
        </Wrapper>
      </Container>
    </Zoom>
  );
};

export default Popup;
