import { useState } from "react";
import {
  Fade,
  Box,
  Stack,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Slider,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";

import testQuestions from "./testQuestions.json";

// Icons
import CloseIcon from "@mui/icons-material/Close";

const SurveyContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  top: "50%",
  width: "75%",
  height: "75%",
  transform: "translate(-50%, -50%)",
  boxShadow: " 0px 0px 25px 0px rgba(0,0,0,0.75)",
  borderRadius: 3,
  backgroundColor: "#fff",
  zIndex: 999,
}));

const SurveyWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  backgroundColor: "#fff",
  outline: "none",
}));

const SurveyHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0, 3, 1, 3),
}));

const StepperHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 1, 2),
  borderBottom: "solid 1px #ccc",
}));

const ContentWrapper = styled(Box)({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& > *": {
    width: "100%",
    height: "100%",
  },
});

const QuestionWrapper = styled(Box)({
  backgroundColor: "#fff",
  overflowY: "auto",
  overflowX: "hidden",
  borderRadius: 3,
  padding: "8px 16px",
});

const FinishedWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
});

const Footer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderTop: "solid 1px #ccc",
}));

const CloseButton = styled(CloseIcon)({
  cursor: "pointer",
  transition: ".25s",
  "&:hover": {
    transform: "scale(1.15)",
  },
});

const Survey = ({ closeSurvey, companyName }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [surveyFinished, setSurveyFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const MAX_STEPS = 3;

  const handleNextStep = () => {
    setActiveStep((previousStep) => {
      if (previousStep + 1 > MAX_STEPS) return previousStep;
      return previousStep + 1;
    });
  };

  const handlePreviousStep = () => {
    setActiveStep((previousStep) => {
      if (previousStep - 1 < 0) return previousStep;
      return previousStep - 1;
    });
  };

  const handleSurveyFinish = () => {
    setIsLoading(true);
    setSurveyFinished(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  return (
    <SurveyContainer>
      <SurveyWrapper>
        {!surveyFinished ? (
          <ContentWrapper>
            <Stack>
              <SurveyHeader>
                <Typography variant="h6">Survey: {companyName}</Typography>
                <CloseButton onClick={closeSurvey} />
              </SurveyHeader>
              <StepperHeader>
                <Stepper activeStep={activeStep}>
                  {testQuestions.map((item) => {
                    return (
                      <Step key={item.id}>
                        <StepLabel>{item.question_set}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </StepperHeader>
              <QuestionWrapper>
                {testQuestions
                  .filter((e) => e.step === activeStep)
                  .map((item) =>
                    item.question_list.map((question, i) => (
                      <Box key={i} mb={3}>
                        <Typography variant="subtitle1">
                          <b>{question}</b>
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="space-between"
                        >
                          <Typography variant="body1" sx={{ opacity: 0.75 }}>
                            Negative
                          </Typography>
                          <Typography variant="body1" sx={{ opacity: 0.75 }}>
                            Positive
                          </Typography>
                        </Stack>
                        <Slider
                          marks
                          defaultValue={30}
                          step={10}
                          min={0}
                          max={60}
                        />
                      </Box>
                    ))
                  )}
              </QuestionWrapper>
              <Footer>
                <Button
                  sx={{ width: 80 }}
                  disabled={activeStep === 0}
                  color="button"
                  variant="contained"
                  onClick={handlePreviousStep}
                >
                  Back
                </Button>
                <Typography>{`${activeStep + 1} / ${
                  MAX_STEPS + 1
                }`}</Typography>
                {activeStep === MAX_STEPS ? (
                  <Button
                    sx={{ width: 80 }}
                    color="button"
                    variant="contained"
                    onClick={handleSurveyFinish}
                  >
                    Finish
                  </Button>
                ) : (
                  <Button
                    sx={{ width: 80 }}
                    disabled={activeStep === MAX_STEPS}
                    color="button"
                    variant="contained"
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                )}
              </Footer>
            </Stack>
          </ContentWrapper>
        ) : (
          <FinishedWrapper>
            {!isLoading ? (
              <Fade in={surveyFinished}>
                <Stack spacing={1}>
                  <Typography variant="overline" sx={{ fontSize: 24 }}>
                    Thank you for your answers!
                  </Typography>
                  <Button
                    color="button"
                    variant="contained"
                    onClick={closeSurvey}
                  >
                    Close Survey
                  </Button>
                </Stack>
              </Fade>
            ) : (
              <Fade appear in={true}>
                <Box>
                  <CircularProgress size={80} />
                </Box>
              </Fade>
            )}
          </FinishedWrapper>
        )}
      </SurveyWrapper>
    </SurveyContainer>
  );
};

export default Survey;
