import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useCallback, useMemo } from "react";
import Login from "./pages/Login";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import CarbonRadarIcon from "./images/CarbonRadar-icon1.png";
import PrivateRoutes from "./pages/privateRoutes";

const innerTheme = createTheme({
  palette: {
    primary: {
      main: "#11314f",
      medium: "#6388ab",
      light: "#85a8c9",
      text: "#fff",
    },
    secondary: {
      main: "#18456e",
    },
    chart: {
      bar: "#11314f",
      line: "#6388ab",
    },
    button: {
      main: "#57C0BB",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 0,
          color: "#fff",
          fontFamily: "content",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#4ba6a1",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontFamily: "subheader",
        },
        subtitle1: {
          fontFamily: "subheader",
        },
        body1: {
          fontFamily: "content",
        },
        caption: {
          fontFamily: "content",
        },
      },
    },
  },
});

function App() {
  const API = useMemo(
    () =>
      window.location.href.includes("localhost")
        ? "http://localhost:5555/api"
        : "https://shark-app-vp92s.ondigitalocean.app/api",
    []
  );

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isAuthenticated, setIsAutheticated] = React.useState(false);
  const [isSignedOut, setIsSignedOut] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [accountType, setAccountType] = React.useState(null);

  const logout = () => {
    axios.get(API + "/logout").then((response) => {
      if (response.data === "Successfully logged out.") {
        setIsAutheticated(false);
        setIsSignedOut(true);
      }
    });
  };

  const checkAuth = useCallback(() => {
    setIsLoaded(false);
    axios.get(API + "/checkLogin").then((response) => {
      if (response.data.status === "authorized") {
        setUsername(response.data.username);
        setAccountType(response.data.accountType);
        setIsAutheticated(true);
        setIsLoaded(true);
      } else {
        setIsAutheticated(false);
        setIsLoaded(true);
      }
    });
  }, [API]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <ThemeProvider theme={innerTheme}>
      <div className="App">
        <Router>
          <Switch>
            {isLoaded ? (
              <Route
                exact
                path="/login"
                component={(params) => (
                  <Login checkAuth={checkAuth} {...params} API={API} />
                )}
              />
            ) : null}
            {isLoaded ? (
              <Route
                path="/"
                component={() => (
                  <PrivateRoutes
                    API={API}
                    username={username}
                    logout={logout}
                    isLoaded={isLoaded}
                    isAuthenticated={isAuthenticated}
                    signedOut={isSignedOut}
                    accountType={accountType}
                  />
                )}
              />
            ) : (
              <React.Fragment>
                <div className="logo">
                  <img src={CarbonRadarIcon} alt="icon" />
                </div>
              </React.Fragment>
            )}
          </Switch>
        </Router>
      </div>{" "}
    </ThemeProvider>
  );
}

export default App;
