import React, { useState, useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import CarbonRadarLogo from "../../images/CarbonRadar-logo-light.png";
import PersonIcon from "@mui/icons-material/Person";
import BackgroundVideo from "./background.mp4";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import "./login.css";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const axios = require("axios");
axios.defaults.withCredentials = true;

const style = {
  form: {
    marginTop: "2rem",
  },
  button: {
    marginTop: "1rem",
  },
  alert: {
    padding: "5px",
  },
  accountSelectionRow: {
    display: "flex",
    justifyContent: "left",
    verticalAlign: "center",
    cursor: "pointer",
    margin: "0px",
    padding: "15px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    wordBreak: "break-all",
    "&:hover": {
      backgroundColor: "rgb(245, 245, 245)",
    },
  },
};

export default function Login(props) {
  const [loginStatus, setLoginStatus] = useState(false);
  const [signInProcess, setSignInProcess] = useState(false);
  const [forgotPass, setForgotPass] = useState(null);
  const [message, setMessage] = useState("In Progress");
  const [password1, setPassword1] = useState("");
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down("md"));
  const matches = useMediaQuery("(min-width:700px)");
  const [authID, setAuthID] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (window.location.href.includes("?ID=")) {
      const urlParams = new URLSearchParams(window.location.search);
      const authID = urlParams.get("ID");
      setAuthID(authID);
    }
  }, [props.API]);

  useEffect(() => {
    if (authID !== "" && authID) {
      axios
        .get(props.API + "/forgotPasswordAuth", {
          params: {
            ID: authID,
          },
          withCredentials: false,
        })
        .then((response) => {
          setMessage(response.data.message);
          setPassword1(response.data.password);
        });
    }
  }, [props.API, authID]);

  /* function forgotPassword() {
    setForgotPass(true);
  } */

  function copyPass(e) {
    navigator.clipboard.writeText(password1);
  }

  function goBack() {
    if (authID !== "") {
      setTimeout(() => {
        setLoginStatus(false);
        setForgotPass(false);
        history.push("/login");
      });
    } else {
      setForgotPass(false);
    }
  }

  function handleForgotPassword(event) {
    event.preventDefault();
    axios
      .get(props.API + "/forgotPassword", {
        params: {
          email: event.target.email.value,
          href: window.location.href,
        },
        withCredentials: false,
      })
      .then((response) => {
        setLoginStatus(response.data.message);
        setTimeout(() => {
          setLoginStatus(false);
          setForgotPass(false);
        }, 1500);
      });
  }

  function handleClick(event) {
    setSignInProcess(true);
    event.preventDefault();

    axios
      .post(props.API + "/login", {
        username: event.target.username.value,
        password: event.target.password.value,
      })
      .then((Login) => {
        if (Login.data.message === "Login Successful") {
          setLoginStatus("Login successful");
          setTimeout(() => {
            props.checkAuth();
            history.push(Login.data.usertype === "admin" ? "/clients" : "/");
          }, 1500);
        } else {
          setSignInProcess(false);
          setLoginStatus(Login.data.message);
        }
      });
  }

  return (
    <div id="login-root">
      <video id="video-bg" autoPlay loop muted>
        <source src={BackgroundVideo} type="video/mp4" />
      </video>
      {/* <div id="login-bg" /> */}
      <div id="login-box" style={{ display: !matches ? "flex" : "block" }}>
        {!window.location.href.includes("?ID=") ? (
          <div
            id="login-content"
            style={{ background: theme.palette.primary.main }}
          >
            <Box width={1} display="flex" justifyContent="center">
              <img
                id="login-logo"
                src={CarbonRadarLogo}
                alt="carbonradar-logo"
              />
            </Box>

            {!forgotPass ? (
              <form onSubmit={handleClick} sx={style.form}>
                <h1
                  style={{ fontWeight: 500, color: theme.palette.primary.text }}
                >
                  Username
                </h1>
                <TextField
                  type="username"
                  name="username"
                  placeholder="Type your username"
                  id="username"
                  autoComplete="username"
                  variant="standard"
                  margin="none"
                  fullWidth
                  InputProps={{
                    sx: {
                      background: "none",
                      color: theme.palette.primary.text,
                      "&:before": {
                        borderBottom: "solid 1px rgba(255,255,255,.333)",
                      },
                      "&:after": {
                        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
                      },
                    },
                    startAdornment: (
                      <InputAdornment position="start" aria-label="userlogo">
                        <PersonIcon
                          sx={{ color: theme.palette.primary.text }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  color="primary"
                />
                <h1
                  style={{ fontWeight: 500, color: theme.palette.primary.text }}
                >
                  Password
                </h1>
                <TextField
                  type="password"
                  name="password"
                  placeholder="Type your password"
                  id="password"
                  autoComplete="current-password"
                  variant="standard"
                  margin="none"
                  fullWidth
                  InputProps={{
                    sx: {
                      background: "none",
                      color: theme.palette.primary.text,
                      "&:before": {
                        borderBottom: "solid 1px rgba(255,255,255,.333)",
                      },
                      "&:after": {
                        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
                      },
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        aria-label="passwordlogo"
                      >
                        <LockRoundedIcon
                          sx={{ color: theme.palette.primary.text }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  color="primary"
                />
                <Button
                  type={!signInProcess ? "submit" : "button"}
                  disabled={signInProcess}
                  size={breakpoint ? "small" : "medium"}
                  variant="contained"
                  color="button"
                  style={{ marginTop: 15 }}
                  fullWidth
                >
                  Sign in
                </Button>
                {/* <Button
                  sx={{ color: theme.palette.primary.text }}
                  onClick={forgotPassword}
                >
                  Forgot password
                </Button> */}
              </form>
            ) : (
              <div>
                <form onSubmit={handleForgotPassword} sx={style.form}>
                  <h1
                    style={{
                      fontWeight: 500,
                      color: theme.palette.primary.text,
                    }}
                  >
                    Email
                  </h1>
                  <TextField
                    type="email"
                    name="email"
                    placeholder="Type your email"
                    id="email"
                    autoComplete="email"
                    variant="standard"
                    margin="none"
                    fullWidth
                    InputProps={{
                      sx: {
                        background: "none",
                        color: theme.palette.primary.text,
                        "&:before": {
                          borderBottom: "solid 1px rgba(255,255,255,.333)",
                        },
                        "&:after": {
                          borderBottom: `solid 2px ${theme.palette.secondary.main}`,
                        },
                      },
                      startAdornment: (
                        <InputAdornment position="start" aria-label="userlogo">
                          <PersonIcon
                            sx={{ color: theme.palette.primary.text }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    color="primary"
                  />
                  <Button
                    type={"submit"}
                    size={breakpoint ? "small" : "medium"}
                    variant="contained"
                    color="button"
                    style={{ marginTop: 15 }}
                    fullWidth
                  >
                    Send Email
                  </Button>
                </form>
                <Button
                  onClick={goBack}
                  sx={{ color: theme.palette.primary.text }}
                >
                  Back to login
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div id="login-content">
            <Box width={1} display="flex" justifyContent="center">
              <img
                id="login-logo"
                src={CarbonRadarLogo}
                alt="carbonradar-logo"
              />
            </Box>
            <div style={{ padding: 15 }}>
              {message} {password1}
            </div>
            <Button onClick={copyPass}>Copy password to clipboard</Button>
            <Button onClick={goBack}>Back to login</Button>
          </div>
        )}
        {loginStatus ? (
          <MuiAlert
            style={{
              position: !matches ? "absolute" : "relative",
              width: "100%",
              borderRadius: 0,
              bottom: 0,
            }}
            elevation={6}
            variant="filled"
            severity={
              loginStatus === "Login successful" ||
              loginStatus === "Email sent successfully"
                ? "success"
                : "error"
            }
          >
            {loginStatus}
          </MuiAlert>
        ) : null}
      </div>
    </div>
  );
}
