const removeDuplicates = (array, key) => {
  if (!array) return null;
  const filterKeys = {};
  const filtered = array.reduce((acc, item) => {
    if (item[key] === null || !(item[key] in filterKeys)) {
      filterKeys[item[key]] = true;
      acc.push(item);
    }

    return acc;
  }, []);
  return filtered;
};

export { removeDuplicates };
