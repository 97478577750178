import React, { useEffect } from "react";
import axios from "axios";
import MaterialTable from "components/Material-Table";
import moment from "moment";
import NewUserModal from "./newUser";
import NewPasswordModal from "./newPassword";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";

// Icons
import PasswordIcon from "@mui/icons-material/Password";
import CloseIcon from "@mui/icons-material/Close";

const { v4: uuidv4 } = require("uuid");

export default function ClientManagment(props) {
  const [clients, setClients] = React.useState(null);
  const [isForbidden, setIsForbidden] = React.useState(null);
  const [newPass, setNewPass] = React.useState(makepassword(8));
  const [userID, setUserID] = React.useState(null);
  const [userModalOpen, setUserModalOpen] = React.useState(false);
  const [newPassModalOpen, setNewPassModalOpen] = React.useState(false);

  const [copyTextAlert, setCopyTextAlertState] = React.useState(false);

  const theme = useTheme();

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    setCopyTextAlertState(true);
  };

  function makepassword(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function addParentIDs(data) {
    data
      .filter((x, i) => x.maintainer === "false")
      .forEach((y, i) => {
        y.parentId = data.find(
          (data) => data.client_id === y.client_id && data.maintainer === "true"
        ).id;
      });
  }

  useEffect(() => {
    axios.get(props.API + "/getClients").then((data) => {
      if (data.data.message !== "Forbidden") {
        addParentIDs(data.data);
        setClients(data.data);
      } else {
        setIsForbidden(true);
      }
    });

    return () => {
      setClients(null);
      setIsForbidden(null);
    };
  }, [props.API]);

  function insertDataRow(data) {
    axios
      .get(props.API + "/add-client", {
        params: {
          id: data.id,
          client_name: data.client_name ? data.client_name : null,
          client_id: data.client_id ? data.client_id : null,
          user: data.user ? data.user : null,
          maintainer: "true",
          type: data.type ? data.type : null,
          pass: newPass,
        },
      })
      .then((data) => {});
  }

  function updateDataRow(data) {
    axios.put(props.API + "/update-client", null, {
      params: {
        id: data.id ? data.id : "",
        client_name: data.client_name ? data.client_name : "",
        client_id: data.client_id ? data.client_id : "",
        user: data.user ? data.user : "",
        maintainer: data.maintainer ? data.maintainer : "",
        type: data.type ? data.type : "",
      },
    });
  }

  function handleUserModal() {
    setUserModalOpen((prev) => !prev);
  }

  function handleNewPasswordModal() {
    setNewPassModalOpen((prev) => !prev);
  }

  async function addUserToAccount(name, password, type) {
    const data = clients.find(
      (x) => x.client_id === userID && x.maintainer === "true"
    );

    const newData = {
      id: uuidv4(),
      client_id: data.client_id,
      client_name: data.client_name,
      user: name,
      maintainer: "false",
      hash: password,
      type: type,
    };

    const result = await axios.get(props.API + "/add-user-to-account", {
      params: newData,
    });

    if (result.data && result.data.affectedRows === 1) {
      newData.parentId = data.id;
      newData.hash =
        "$argon2i$v=19$m=16,t=2,p=1$Mkd0MlhETnRmZXBSa0o5Rg$BjAgU9Hqlz5jwJG8Hgwl2g"; // Placeholder
      newData.account_created = moment().format("YYYY-MM-DD");
      setClients((prevValues) => [newData, ...prevValues]);
      handleUserModal();
      setUserID(null);
    }
  }

  return (
    <div>
      {!isForbidden ? (
        <MaterialTable
          title={<Typography variant="h6">Carbon Radar Accounts</Typography>}
          columns={[
            {
              title: "ID",
              field: "id",
              hidden: true,
            },
            {
              title: "Parent ID",
              field: "parentId",
              hidden: true,
            },
            {
              title: "Client ID",
              field: "client_id",
              hidden: true,
            },
            {
              title: "Client Name",
              field: "client_name",
              validate: (rowData) => Boolean(rowData.client_name),
            },
            {
              title: "Email",
              field: "user",
              validate: (rowData) => Boolean(rowData.user),
            },
            {
              title: "Password",
              field: "hash",
              render: (rowData) => (
                <>
                  <PasswordIcon style={{ opacity: 0.5 }} />
                </>
              ),
              editComponent: (props) =>
                !props.value ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ margin: 0, marginRight: 5 }}>{newPass}</p>{" "}
                    <Button
                      onClick={() => copyText(newPass)}
                      style={{ fontSize: "0.75em" }}
                    >
                      Copy to Clipboard
                    </Button>
                  </div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PasswordIcon style={{ opacity: 0.5 }} />
                  </div>
                ),
            },
            {
              title: "Account Type",
              field: "type",
              editComponent: (props) => (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  value={props.value}
                  onChange={(newValue) => props.onChange(newValue.target.value)}
                  label="Type"
                >
                  <MenuItem value={"client"}>Client</MenuItem>
                  <MenuItem value={"admin"}>Admin</MenuItem>
                </Select>
              ),
              render: (rowData) =>
                rowData.type
                  ? rowData.type
                      .split("")
                      .map((e, i) => (i === 0 ? e.toUpperCase() : e))
                      .reduce((a, b) => a + b)
                  : "-",
              initialEditValue: "client",
            },
            {
              title: "Account Created",
              field: "account_created",
              defaultSort: "desc",
              render: (rowData) =>
                rowData.account_created
                  ? moment(rowData.account_created).format("DD/MM/YYYY")
                  : "No date",
              editComponent: (props) => (
                <p style={{ margin: 0 }}>{moment().format("DD/MM/YYYY")}</p>
              ),
            },
          ]}
          data={clients}
          /* parentChildData={(row, rows) =>
            rows.find((a) => a.id === row.parentId)
          } */
          actions={[
            (rowData) => ({
              tooltip: "Generate New Password",
              icon: () => <PasswordIcon />,
              onClick: (e, rowData) => {
                setUserID(rowData);
                handleNewPasswordModal();
              },
            }),
          ]}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                newData.account_created = moment().format("YYYY-MM-DD");
                newData.id = uuidv4();
                newData.client_id = uuidv4();
                newData.maintainer = "true";
                insertDataRow(newData);
                setTimeout(() => {
                  setClients([newData, ...clients]);
                  setNewPass(makepassword(8));
                  resolve();
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                newData.logo = clients.find((x) => x.id === newData.id).logo;
                updateDataRow(newData);
                setTimeout(() => {
                  const dataUpdate = [...clients];
                  const index = dataUpdate.findIndex(
                    (data) => data.id === oldData.id
                  );
                  dataUpdate[index] = newData;
                  setClients([...dataUpdate]);
                  resolve();
                }, 600);
              }),
            onRowDelete: (newData) =>
              new Promise((resolve) => {
                axios
                  .delete(props.API + "/delete-client", {
                    params: {
                      id:
                        newData.maintainer === "true"
                          ? newData.client_id
                            ? newData.client_id
                            : null
                          : newData.id
                          ? newData.id
                          : null,
                      maintainer: newData.maintainer
                        ? newData.maintainer
                        : null,
                    },
                  })
                  .then((data) => {
                    if (newData.maintainer === "true") {
                      setClients(
                        clients.filter(
                          (data) => data.client_id !== newData.client_id
                        )
                      );
                    } else {
                      setClients(
                        clients.filter((data) => data.id !== newData.id)
                      );
                    }
                    resolve();
                  });
              }),
          }}
        />
      ) : (
        <h1 style={{ opacity: 0.75, textAlign: "center", marginTop: "25vh" }}>
          You need to be an admin user to see this content.
        </h1>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={copyTextAlert}
        autoHideDuration={2500}
        onClose={() => setCopyTextAlertState(false)}
        message="Copied to Clipboard"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setCopyTextAlertState(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <NewUserModal
        open={userModalOpen}
        handleModal={handleUserModal}
        addUser={addUserToAccount}
        makepassword={makepassword}
        copyText={copyText}
        theme={theme}
      />
      <NewPasswordModal
        API={props.API}
        open={newPassModalOpen}
        handleModal={handleNewPasswordModal}
        makepassword={makepassword}
        userID={userID}
        copyText={copyText}
        theme={theme}
      />
    </div>
  );
}
