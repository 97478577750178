import { Box, Typography /* useMediaQuery */ } from "@mui/material";
import { styled } from "@mui/system";

//import PlaceholderLogo from "images/logo-placeholder.png";

import moment from "moment";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "8px 4px",
  justifyContent: "space-between",
  borderBottom: "solid 1px #ccc",
  borderLeft: `solid 4px ${theme.palette.secondary.main}`,
}));

const Block = styled(Box)(({ theme, direction, flex }) => ({
  display: "flex",
  padding: "0px 6px",
  flexDirection: direction ? direction : "row",
  alignItems: direction ? "flex-start" : "center",
  flex: flex ? flex : 0,
}));

const Link = styled("a")({
  textDecoration: "none",
  color: "#000",
  opacity: 0.8,
  "&:hover": { textDecoration: "underline", color: "#000" },
});

const Description = ({ children }) => {
  const MAX_LENGTH = 250;

  return (
    <Typography sx={{ opacity: 0.75 }}>
      {children.length > MAX_LENGTH
        ? children.substring(0, MAX_LENGTH) + "..."
        : children}
    </Typography>
  );
};

const NewsFeedItem = ({ date, title, description, url }) => {
  //const matches = useMediaQuery("(min-width:850px)");

  return (
    <Wrapper>
      {/* <Block P={1} sx={{ display: matches ? "flex" : "none" }}>
        <img width={150} src={PlaceholderLogo} alt="logo" />
      </Block> */}
      <Block direction="column" flex={1}>
        <Typography sx={{ fontWeight: "bolder", fontSize: 14, opacity: 0.75 }}>
          {moment(date).format("YYYY/MM/DD")}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "1.1em" }}>
          <Link href={url} target="_blank" rel="noopener noreferrer">
            {title}
          </Link>
        </Typography>
        <Description>{description}</Description>
      </Block>
    </Wrapper>
  );
};

export default NewsFeedItem;
