import { Fragment, useState, useEffect } from "react";
import { Box, Pagination, Typography } from "@mui/material";
import moment from "moment";
import NewsFeedItem from "./newsFeedItem";
import newsArray from "./newsArray.json";

const NEWS_PER_PAGE = 5;

const NewsFeed = () => {
  const [news, setNews] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const paginatedNews = newsArray
      .sort(
        (a, b) => moment(b.date, "MM/DD/YYYY") - moment(a.date, "MM/DD/YYYY")
      )
      .map((item, i) => ({
        ...item,
        page: Math.ceil((i + 1) / NEWS_PER_PAGE),
      }));

    setNews(paginatedNews);

    return () => {
      setNews(null);
      setPage(1);
    };
  }, []);

  const handlePageChange = (e, value) => {
    if (value === page) return;
    setPage(value);
  };

  return (
    <>
      <Box mb={1} display="flex" justifyContent="space-between">
        <Typography variant="h6">Newsfeed</Typography>
        {news && (
          <Pagination
            variant="outlined"
            shape="rounded"
            count={Math.ceil(news.length / NEWS_PER_PAGE)}
            onChange={handlePageChange}
          />
        )}
      </Box>
      {news ? (
        <Box
          sx={{
            border: "solid 1px #ccc",
            borderBottom: "none",
          }}
        >
          {news
            .filter((e) => e.page === page)
            .map((item) => (
              <Fragment key={item.id}>
                <NewsFeedItem
                  date={item.date}
                  title={item.title}
                  description={item.description}
                  url={item.url}
                />
              </Fragment>
            ))}
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </>
  );
};

export default NewsFeed;
