import { Fragment, useState } from "react";
import { Typography, Button } from "@mui/material";

const Description = ({ children }) => {
  const [isHidden, setIsHidden] = useState(true);

  const maxLength = 225;
  const handleText = () => {
    setIsHidden((previous) => !previous);
  };

  return (
    <Fragment>
      <Typography component="div">
        {children
          ? isHidden
            ? children.substring(0, maxLength) + "... "
            : children
          : " - "}
      </Typography>
      {children && children.length > maxLength && (
        <Button
          size="small"
          variant="text"
          onClick={handleText}
          sx={{ padding: "6px 0" }}
        >
          {isHidden ? "Show More" : "Show Less"}
        </Button>
      )}
    </Fragment>
  );
};

export default Description;
