import {
  Card as MUICard,
  CardContent,
  CardMedia,
  Box,
  Typography,
} from "@mui/material/";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";

const CardContainer = styled(MUICard)(({ theme }) => ({
  cursor: "pointer",
  transition: ".25s",
  borderLeft: `solid 7px ${theme.palette.secondary.main}`,
  "&:hover": {
    transform: "scale(1.025)",
  },
}));

const Card = ({ title, path, image }) => {
  const history = useHistory();

  const handleCardClick = () => {
    history.push(path);
  };

  return (
    <CardContainer
      onClick={handleCardClick}
      sx={{
        minWidth: 300,
      }}
    >
      {/* <CardMedia
        component="img"
        height="140"
        image="/static/images/cards/contemplative-reptile.jpg"
        alt="green iguana"
      /> */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        px={1.5}
        py={1}
      >
        <Box
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            border: "solid 1px #ddd",
            width: "100%",
            height: 200,
          }}
        ></Box>
      </Box>
      <CardContent>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </CardContent>
    </CardContainer>
  );
};

export default Card;
