import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Stack,
  Button,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";
import axios from "axios";

const CloseButton = styled(CloseIcon)({
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const Container = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  backgroundColor: "#fff",
  boxShadow: 24,
});

const NewPassword = ({
  API,
  open,
  handleModal,
  makepassword,
  userID,
  copyText,
  theme,
}) => {
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!open) return null;
    setPassword(makepassword(10));
    return () => {
      setPassword("");
      setConfirmation(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const saveNewPassword = async () => {
    try {
      setIsSaving(true);
      const params = { client_id: userID.client_id, newPassword: password };
      const result = await axios.get(API + "/update-client-password", {
        params,
      });
      if (result.status === 200) {
        handleModal();
        setIsSaving(false);
        return;
      }
      throw new Error("Failed to update client password!");
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  return (
    <Modal open={open} onClose={handleModal}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={1}
          px={2}
          sx={{ backgroundColor: theme.palette.primary.main, color: "#fff" }}
        >
          <Typography variant="h6">Generate New Password</Typography>
          <CloseButton onClick={handleModal} />
        </Box>

        {confirmation ? (
          <Box m={2} mb={1}>
            <Typography>
              Are you sure you want to set <b>{password}</b> as the new password
              for <b>{userID.user}</b> ?
            </Typography>
            <Stack my={1} direction="row" spacing={1}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={handleModal}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                disabled={isSaving}
                size="small"
                variant="contained"
                onClick={saveNewPassword}
              >
                Save new Password
              </Button>
            </Stack>
          </Box>
        ) : (
          <>
            <Stack m={2} direction="row" spacing={1} alignItems="center">
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => setPassword(makepassword(10))}
              >
                Generate Password
              </Button>
              <TextField
                disabled={true}
                label="Password"
                variant="outlined"
                value={password}
              />
              <IconButton onClick={() => copyText(password)}>
                <ContentCopyIcon />
              </IconButton>
            </Stack>
            <Stack mx={2} mb={1} direction="row" spacing={1}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={handleModal}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={() => setConfirmation(true)}
              >
                Save
              </Button>
            </Stack>
          </>
        )}
      </Container>
    </Modal>
  );
};

export default NewPassword;
