import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { Link, withRouter } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import GroupIcon from "@mui/icons-material/Group";
import MenuIcon from "@mui/icons-material/Menu";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToApp";
import HomeIcon from "@mui/icons-material/Home";
import Toolbar from "@mui/material/Toolbar";
import CarbonRadarLogo from "../../images/CarbonRadar-logo-light.png";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const theme = useTheme();
  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  //Account menu states
  const [anchorElaccount, setAnchorElaccount] = React.useState(false);

  const handleAccountClose = () => {
    setAnchorElaccount(false);
  };

  const handleAccountMenu = (event) => {
    setAnchorElaccount(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sideNavList =
    props.accountType === "admin"
      ? [
          {
            name: "Front Page",
            path: "/",
            icon: (
              <HomeIcon
                style={{
                  color: theme.palette.primary.text,
                }}
              />
            ),
          },
          {
            name: "Clients",
            path: "/clients",
            icon: (
              <GroupIcon
                style={{
                  color: theme.palette.primary.text,
                }}
              />
            ),
          },
          {
            name: "Company Data",
            path: "/company-data",
            icon: (
              <BarChartIcon
                style={{
                  color: theme.palette.primary.text,
                }}
              />
            ),
          },
        ]
      : props.accountType === "client"
      ? [
          {
            name: "Front Page",
            path: "/",
            icon: (
              <HomeIcon
                style={{
                  color: theme.palette.primary.text,
                }}
              />
            ),
          },
          {
            name: "Company Data",
            path: "/company-data",
            icon: (
              <BarChartIcon
                style={{
                  color: theme.palette.primary.text,
                }}
              />
            ),
          },
        ]
      : [];

  const drawer = (
    <div>
      <Link to="/">
        <img
          src={CarbonRadarLogo}
          style={{
            width: "100%",
            padding: 30,
          }}
          alt="carbonradar-logo"
        />
      </Link>

      <Divider />
      <List sx={{ p: 0 }}>
        {sideNavList
          ? sideNavList.map((data, index) => (
              <ListItem
                style={{
                  color: theme.palette.primary.text,
                  background:
                    props.location.pathname === data.path
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main,
                }}
                component={Link}
                to={data.path}
                button
                key={index}
                onClick={() => setMobileOpen(false)}
              >
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText
                  primary={<Typography variant="body1">{data.name}</Typography>}
                />
              </ListItem>
            ))
          : null}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar style={{ justifyContent: "space-between", minHeight: 45 }}>
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div>
            <Button
              aria-controls="menu-appbar"
              aria-haspopup="true"
              variant="text"
              onClick={handleAccountMenu}
              style={{ boxShadow: "none", color: "#fff" }}
            >
              {props.accountType === "client" ? (
                <AccountCircleIcon style={{ marginRight: 5 }} />
              ) : props.accountType === "admin" ? (
                <ManageAccountsIcon style={{ marginRight: 5 }} />
              ) : null}
              {props.username}
            </Button>
          </div>
          <Menu
            style={{ marginTop: "28px" }}
            id="menu-appbar"
            anchorEl={anchorElaccount}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={!!anchorElaccount}
            onClose={handleAccountClose}
          >
            <MenuItem onClick={props.logout}>
              <ListItemIcon>
                <ExitToAppRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            sx: {
              backgroundColor: "primary.main",
              color: "#fff",
            },
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              background: theme.palette.primary.main,
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          PaperProps={{
            sx: {
              backgroundColor: "primary.main",
              color: "#fff",
            },
          }}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              background: theme.palette.primary.main,
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default withRouter(ResponsiveDrawer);
