import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Stack,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const CloseButton = styled(CloseIcon)({
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const Container = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  backgroundColor: "#fff",
  boxShadow: 24,
});

const NewUser = ({
  open,
  handleModal,
  addUser,
  makepassword,
  copyText,
  theme,
}) => {
  const [value, setValue] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("client");

  useEffect(() => {
    if (!open) return null;
    setPassword(makepassword(10));
    return () => {
      setValue("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    if (!username) return;
    addUser(value, password, type);
  };

  return (
    <Modal open={open} onClose={handleModal}>
      <Container>
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            px={2}
            sx={{ backgroundColor: theme.palette.primary.main, color: "#fff" }}
          >
            <Typography variant="h6">Add User To Account</Typography>
            <CloseButton onClick={handleModal} />
          </Box>

          <Box m={1}>
            <TextField
              fullWidth
              size="small"
              name="username"
              label="Username"
              variant="outlined"
              onChange={(e) => setValue(e.target.value)}
            />
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <InputLabel>Account Type</InputLabel>
              <Select
                value={type}
                label="Account Type"
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value={"client"}>Client</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            m={1}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
          >
            <Button
              sx={{ width: "50%" }}
              onClick={() => setPassword(makepassword(10))}
              size="small"
              color="secondary"
              variant="contained"
            >
              Generate Password
            </Button>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "50%" }}
            >
              <Typography variant="subtitle1" sx={{ letterSpacing: 1.5 }}>
                {password}
              </Typography>
              <Button onClick={() => copyText(password)}>Copy</Button>
            </Box>
          </Box>
          <Stack direction="row" spacing={1} m={1}>
            <Button
              fullWidth
              color="button"
              variant="contained"
              onClick={handleModal}
            >
              Cancel
            </Button>
            <Button
              disabled={value.length === 0}
              fullWidth
              color="button"
              variant="contained"
              type="submit"
            >
              Add User
            </Button>
          </Stack>
        </form>
      </Container>
    </Modal>
  );
};

export default NewUser;
