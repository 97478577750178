import { Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import { Route, Redirect, withRouter, Switch } from "react-router-dom";

// Nav
import SideNav from "../components/Sidenav";

// Pages
import Frontpage from "./Frontpage";
import ClientManagment from "./Admin/ClientManagment";
import CompanyData from "./CompanyData";
import PageNotFound from "./404";

export default withRouter(function PrivateRoutes(props) {
  const myRef = useRef();
  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        ref={myRef}
        render={(params) =>
          props.isAuthenticated || props.location.pathname === "/login" ? (
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                width: { sm: `calc(100% - ${240}px)` },
              }}
            >
              <Toolbar style={{ minHeight: 45 }} />
              <Component {...params} />
            </Box>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: params.location,
                  signedOut: props.signedOut,
                },
              }}
            />
          )
        }
      />
    );
  };

  return props.isLoaded ? (
    <React.Fragment>
      <SideNav
        accountType={props.accountType}
        logout={props.logout}
        username={props.username}
      />
      <Switch>
        <PrivateRoute
          exact
          path="/"
          component={() => <Frontpage API={props.API} />}
        />
        <PrivateRoute
          exact
          path="/clients"
          component={() => <ClientManagment API={props.API} />}
        />
        <PrivateRoute
          exact
          path="/company-data"
          component={() => <CompanyData API={props.API} />}
        />
        <PrivateRoute component={() => <PageNotFound />} />
      </Switch>
    </React.Fragment>
  ) : null;
});
