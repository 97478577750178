import ApexCharts from "components/ApexCharts";
import { useTheme } from "@mui/material";

const chartStyle = {
  height: 350,
  width: "100%",
};

const RevenueEmployeeChart = ({ data, title = "" }) => {
  const theme = useTheme();
  return (
    <div style={chartStyle}>
      <ApexCharts
        type={"line"}
        series={[
          {
            name: "Revenue",
            type: "column",
            data: data ? data.map((e) => e.revenue) : [],
          },
          {
            name: "Employees",
            type: "line",
            data: data ? data.map((e) => e.employees) : [],
          },
        ]}
        height={"100%"}
        options={{
          colors: [theme.palette.chart.bar, theme.palette.chart.line],
          chart: {
            type: "line",
            width: "100%",
            toolbar: {
              show: false,
            },
          },
          legend: {
            position: "top",
            showForSingleSeries: true,
          },
          title: {
            text: title,
            align: "center",
            style: {
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "subheader",
            },
          },
          tooltip: {
            y: {
              formatter: (value, opts) => {
                return value ? value.toLocaleString("fi") : 0;
              },
            },
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },

              tooltip: {
                enabled: true,
              },
            },
            {
              seriesName: "Employees",
              opposite: true,
              axisTicks: {
                show: true,
              },
            },
          ],
          labels: data ? data.map((e) => e.emission_year) : [],
        }}
      />
    </div>
  );
};

export default RevenueEmployeeChart;
