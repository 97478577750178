import { Fragment, useState, useEffect } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/system";

// Icons
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const TableWrapper = styled(Box)({
  overflow: "auto",
  border: "solid 1px #ddd",
  backgroundColor: "#fff",
});

const Table = styled("table")({
  width: "100%",
  overflow: "hidden",
  backgroundColor: "#fff",
});

const TableHead = styled("thead")({
  overflow: "hidden",
});

const TableBody = styled("tbody")({
  overflow: "hidden",
});

const TableRow = styled("tr")(({ theme }) => ({
  borderBottom: "solid 1px #ddd",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
  },
  "&:last-of-type": {
    borderBottom: "none",
  },
}));

const TableHeaderCell = styled("th")(({ theme }) => ({
  padding: "4px 16px",
  backgroundColor: theme.palette.primary.main,
  fontSize: "15x",
  fontFamily: "subheader",
  color: "#fff",
  fontWeight: "bold",
  borderRight: "solid 1px #ddd",
  borderBottom: "solid 1px #ddd",
  textAlign: "center",
  width: 175,
  "&:first-of-type": {
    width: 225,
  },
  "&:last-of-type": {
    borderRight: "none",
  },
}));

const TableDataCell = styled("td")(({ theme }) => ({
  padding: "4px 8px",
  fontSize: "14px",
  borderRight: "solid 1px #ddd",
  fontFamily: "content",
  textAlign: "center",
  position: "relative",
  "&:first-of-type": {
    textAlign: "left",
  },
  "&:last-of-type": {
    borderRight: "none",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.medium,
  },
  /* "&:nth-of-type(even)": {
    backgroundColor: "#ededed",
  }, */
}));

const TableCellText = styled(Typography)(({ theme, percentage }) => ({
  color:
    typeof percentage === "number"
      ? percentage >= 0
        ? "green"
        : "red"
      : "none",
  fontSize: 13,
}));

const TooltipIcon = styled(HelpOutlineIcon)({
  opacity: 0.3,
  transform: "scale(0.75)",
  transition: "0.05s",
  "&:hover": {
    opacity: 0.5,
  },
});

const yearlyRowTitles = [
  {
    rowLabel: "Revenue ( € )",
    key: "revenue",
  },
  {
    rowLabel: "Employees",
    key: "employees",
  },
  {
    rowLabel: "Total Emissions ( t CO2e )",
    key: "total_emissions",
  },
  {
    rowLabel: "Scope 1 Emissions ( t CO2e )",
    key: "scope1_emissions",
    remark: "scope1_emission_remarks",
  },
  {
    rowLabel: "Scope 2 Location-Based Emissions ( t CO2e )",
    key: "scope2_emissions_locationbased",
    remark: "scope2_emissions_locationbased_remarks",
  },
  {
    rowLabel: "Scope 2 Market-Based Emissions ( t CO2e )",
    key: "scope2_emissions_marketbased",
    remark: "scope2_emissions_marketbased_remarks",
  },
  {
    rowLabel: "Scope 3 Emissions ( t CO2e )",
    key: "scope3_emissions",
    remark: "scope3_remarks",
  },
  {
    rowLabel: "Scope 1 & 2 Total Emissions ( t CO2e )",
    key: "scope_1_2_emissions",
  },
];

const comparisonRowTitles = [
  {
    rowLabel: "Revenue-",
    key: "revenue",
  },
  {
    rowLabel: "Employees-",
    key: "employees",
  },
  {
    rowLabel: "Total Emissions-",
    key: "total_emissions",
  },
];

const relativeRowTitles = [
  {
    rowLabel: "CO2e / Revenue ( Turnover )",
    key: "",
  },
  {
    rowLabel: "CO2e / Employees",
    key: "",
  },
  {
    rowLabel: "CO2e / EBITDA",
    key: "",
  },
  {
    rowLabel: "CO2e / Market Cap",
    key: "",
  },
];

const handleValue = (value, data) => {
  if (!value) return " ";
  else if (typeof value === "string" || typeof value === "object") {
    const find = yearlyRowTitles.find(
      (e) => e.rowLabel === value && e.hasOwnProperty("remark")
    );
    if (find)
      return (
        <Box component="span" display="flex" justifyContent="space-between">
          <span>{value}</span>
          {data[find.remark] && (
            <Tooltip title={data[find.remark]}>
              <TooltipIcon />
            </Tooltip>
          )}
        </Box>
      );
    return value;
  } else return value.toLocaleString("fi");
};

const calculatePercentageChange = (value1, value2) => {
  if (typeof value1 === "string") return value1;
  if (typeof value2 === "string" || !value2) return "";
  const number = parseFloat((((value1 - value2) / value2) * 100).toFixed(2));
  return number;
};

const CompanyTable = ({ data }) => {
  const [headCells, setHeadCells] = useState(null);
  const [tableRowsYearly, setTableRowsYearly] = useState(null);
  const [tableRowsComparison, setTableRowsComparison] = useState(null);
  const [tableRowsRelative, setTableRowsRelative] = useState(null);

  useEffect(() => {
    if (!data) return;
    if (data.length === 0 || (data.length > 0 && !data[0].emission_year)) {
      setHeadCells([]);
      setTableRowsYearly([]);
      setTableRowsComparison([]);
      setTableRowsRelative([]);
      return;
    }

    // Calculate total emissions from scopes if not set
    data.forEach((item) => {
      const scope1 = item.scope1_emissions || 0;
      const scope2 = item.scope2_emissions_marketbased || 0;
      const scope3 = item.scope3_emissions || 0;

      if (!item.total_emissions) {
        item.total_emissions = scope1 + scope2 + scope3;
      }

      item.scope_1_2_emissions = scope1 + scope2;
    });

    const tableHeadCells = data.map((item, i) => ({
      id: i,
      rowLabel: item.emission_year,
    }));

    const handleRowTitles = (array, setter) => {
      const tableRowArray = [];
      array.forEach((item) => {
        const arr = [];
        arr.push(item.rowLabel);
        data.forEach((e) => {
          arr.push(e[item.key]);
        });

        // Create empty slots if row has less than 5 year columns
        if (tableHeadCells.length < 5) {
          for (let i = 0; i < 5 - tableHeadCells.length; i++) {
            arr.push("");
          }
        }
        tableRowArray.push(arr);
      });
      setter(tableRowArray);
    };

    handleRowTitles(yearlyRowTitles, setTableRowsYearly);
    handleRowTitles(comparisonRowTitles, setTableRowsComparison);
    handleRowTitles(relativeRowTitles, setTableRowsRelative);
    setHeadCells(tableHeadCells);

    return () => {
      setHeadCells(null);
      setTableRowsYearly(null);
      setTableRowsComparison(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <TableWrapper sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
        {headCells && tableRowsYearly ? (
          <Fragment>
            {tableRowsYearly.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Yearly Data</TableHeaderCell>
                    {headCells.map((cell) => (
                      <TableHeaderCell key={cell.id}>
                        {cell.rowLabel}
                      </TableHeaderCell>
                    ))}
                    {headCells.length < 5 &&
                      Array(5 - headCells.length)
                        .fill(0)
                        .map((e, i) => (
                          <TableHeaderCell key={i}>{""}</TableHeaderCell>
                        ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRowsYearly.map((row, i) => (
                    <TableRow key={i}>
                      {row.map((value, i) => (
                        <TableDataCell key={i}>
                          <TableCellText>
                            {handleValue(value, data ? data[0] : [])}
                          </TableCellText>
                        </TableDataCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography align="center" variant="subtitle1" my={1}>
                No Data
              </Typography>
            )}
          </Fragment>
        ) : (
          <Typography align="center" variant="subtitle1" my={1}>
            Loading...
          </Typography>
        )}
      </TableWrapper>

      <TableWrapper
        sx={{
          borderTop: "none",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}
      >
        {headCells && tableRowsRelative ? (
          <Fragment>
            {tableRowsYearly.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Relative Emissions</TableHeaderCell>
                    {headCells.map((cell) => (
                      <TableHeaderCell key={cell.id}>
                        {cell.rowLabel}
                      </TableHeaderCell>
                    ))}
                    {headCells.length < 5 &&
                      Array(5 - headCells.length)
                        .fill(0)
                        .map((e, i) => (
                          <TableHeaderCell key={i}>{""}</TableHeaderCell>
                        ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRowsRelative.map((row, index) => (
                    <TableRow key={index}>
                      {row.map((value, i) => (
                        <TableDataCell key={i}>
                          <TableCellText>
                            {handleValue(value, data ? data[0] : [])}
                          </TableCellText>
                        </TableDataCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}
          </Fragment>
        ) : null}
      </TableWrapper>

      <TableWrapper
        sx={{
          borderTop: "none",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}
      >
        {headCells && tableRowsComparison ? (
          <Fragment>
            {tableRowsYearly.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Previous Year Comparison</TableHeaderCell>
                    {headCells.map((cell) => (
                      <TableHeaderCell key={cell.id}>
                        {cell.rowLabel}
                      </TableHeaderCell>
                    ))}
                    {headCells.length < 5 &&
                      Array(5 - headCells.length)
                        .fill(0)
                        .map((e, i) => (
                          <TableHeaderCell key={i}>{""}</TableHeaderCell>
                        ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRowsComparison.map((row, index) => (
                    <TableRow key={index}>
                      {row.map((value, i, a) => {
                        const percentage = calculatePercentageChange(
                          value,
                          a[i - 1]
                        );
                        return (
                          <TableDataCell key={i}>
                            <TableCellText percentage={percentage}>
                              {handleValue(percentage) +
                                (percentage ? "%" : " - ")}
                            </TableCellText>
                          </TableDataCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}
          </Fragment>
        ) : null}
      </TableWrapper>
    </Box>
  );
};

export default CompanyTable;
