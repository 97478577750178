import { Fragment, useState, useEffect } from "react";
import {
  Modal,
  Box,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slide,
} from "@mui/material";
import { styled } from "@mui/system";

import CompanyFundamentals from "./CompanyFundamentals";
import CompanyTable from "./CompanyTable";
import CompanySurveyPopup from "./SurveyPopup";
import RevenueEmployeeChart from "./Charts/revenueEmployeesChart";
import EmissionChart from "./Charts/emissionChart";
import ScopeChart from "./Charts/scopeChart";
import ScopeChart2 from "./Charts/scopeChart2";
import ScopeChart3 from "./Charts/scopeChart3";
import ScopeChart4 from "./Charts/scopeChart4";
import ScopeChart5 from "./Charts/scopeChart5";

// Icons
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";

const MUIModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Container = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "90vw",
  overflow: "hidden",
  outline: "none",
  boxShadow: theme.shadows[5],
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#fff",
  borderBottom: "solid 1px #ddd",
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(0.5, 1),
  zIndex: 100,
}));

const Wrapper = styled(Box)(({ theme }) => ({
  padding: 10,
  maxHeight: "90vh",
  overflowY: "auto",
  overflowX: "hidden",
  backgroundColor: "#ededed",
}));

const Blur = styled(Stack)(({ theme, blur }) => ({
  gap: 10,
  transition: ".5s",
  padding: -10,
  filter: `blur(${blur ? "5px" : "0px"}) `,
}));

const ChartWrapper = styled(Box)(({ theme, matches }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 4),
  border: "solid 1px #ddd",
  borderRadius: 5,
  backgroundColor: "#fff",
}));

const CloseButton = styled(CloseIcon)({
  cursor: "pointer",
  transition: ".25s",
  "&:hover": {
    transform: "scale(1.15)",
  },
});

const ChartHandler = ({ selected, data }) => {
  switch (selected) {
    case "revenue/employee":
      return <RevenueEmployeeChart data={data} title="Revenue / Employees" />;
    case "total_emission":
      return <EmissionChart data={data} title="Total Emissions" />;
    case "scope1_emission":
      return (
        <ScopeChart
          data={data}
          title="Scope 1 Emissions"
          obj_key="scope1_emissions"
        />
      );
    case "scope2_location":
      return (
        <ScopeChart2
          data={data}
          title="Scope 2 Location-Based Emissions"
          obj_key="scope2_emissions_locationbased"
        />
      );
    case "scope2_market":
      return (
        <ScopeChart3
          data={data}
          title="Scope 2 Market-Based Emissions"
          obj_key="scope2_emissions_marketbased"
        />
      );
    case "scope3_emission":
      return (
        <ScopeChart4
          data={data}
          title="Scope 3 Emissions"
          obj_key="scope3_emissions"
        />
      );
    case "scope_1_2_emissions":
      return (
        <ScopeChart5
          data={data}
          title="Scope 1 & 2 Total Emissions"
          obj_key="scope_1_2_emissions"
        />
      );
    default:
      return null;
  }
};

const CompanyModal = ({ open, handleModal, data = [], handleNumbers, API }) => {
  const [popupActive, setPopupActive] = useState(false);
  const [surveyMode, setSurveyMode] = useState(false);
  const [emissionData, setEmissionData] = useState(null);
  const [selectedChart, setSelectedChart] = useState("");
  const [availableData, setAvailableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!data || !open) return;
    setIsLoading(true);

    const fetchEmissionData = async (id) => {
      if (!id) return [];

      try {
        const params = { id: id };
        const result = await axios.get(API + "/getEmissionData", { params });
        if (result && result.status === 200) return result.data;
        return [];
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    (async () => {
      const emissionDataArray = await fetchEmissionData(data.ISIN);

      // Find rows that have data
      const arr = [];
      emissionDataArray.forEach((data) => {
        const scope1 = data.scope1_emissions || 0;
        const scope2 = data.scope2_emissions_marketbased || 0;
        const scope3 = data.scope3_emissions || 0;

        if (!data.total_emissions) {
          data.total_emissions = scope1 + scope2 + scope3;
        }

        data.scope_1_2_emissions = scope1 + scope2;

        if (data.revenue && data.employees) arr.push("revenue/employee");
        if (data.total_emissions) arr.push("total_emission");
        if (data.scope1_emissions) arr.push("scope1_emission");
        if (data.scope2_emissions_locationbased) arr.push("scope2_location");
        if (data.scope2_emissions_marketbased) arr.push("scope2_market");
        if (data.scope3_emissions) arr.push("scope3_emission");
        if (data.scope_1_2_emissions) arr.push("scope_1_2_emissions");
      });

      setSelectedChart(arr.length > 0 ? arr[0] : "");
      setAvailableData(arr);
      setEmissionData(emissionDataArray);
    })();

    const popupTimer = setTimeout(() => {
      setPopupActive(true);
    }, 2500);

    setIsLoading(false);

    return () => {
      setPopupActive(false);
      setEmissionData(null);
      setSelectedChart("");
      setAvailableData([]);
      clearTimeout(popupTimer);
    };
  }, [API, data, open]);

  const handlePopup = () => {
    setPopupActive((prev) => !prev);
  };

  const handleSurveyMode = () => {
    setSurveyMode((prev) => !prev);
  };

  const closeSurvey = () => {
    setPopupActive(false);
    setSurveyMode(false);
  };

  const handleChartChange = (e) => {
    setSelectedChart(e.target.value);
  };

  return (
    <MUIModal open={open} onClose={handleModal}>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Container>
          {data ? (
            <Fragment>
              <Header>
                <Typography variant="h6">{data ? data.name : ""}</Typography>
                <CloseButton onClick={handleModal} />
              </Header>
              <Wrapper>
                <CompanySurveyPopup
                  open={popupActive}
                  handlePopup={handlePopup}
                  companyName={data.name}
                  surveyMode={surveyMode}
                  handleSurveyMode={handleSurveyMode}
                  closeSurvey={closeSurvey}
                />
                <Blur blur={popupActive || surveyMode ? 1 : undefined}>
                  <CompanyFundamentals
                    data={data}
                    handleNumbers={handleNumbers}
                  />
                  <CompanyTable data={emissionData} isLoading={isLoading} />

                  {availableData.length > 0 && (
                    <ChartWrapper>
                      <Box
                        m={1}
                        display="flex"
                        justifyContent="flex-end"
                        style={{ width: "100%" }}
                      >
                        <FormControl size="small" style={{ width: 300 }}>
                          <InputLabel>Data</InputLabel>
                          <Select
                            value={selectedChart}
                            label="Data"
                            onChange={handleChartChange}
                          >
                            <MenuItem
                              value={"revenue/employee"}
                              disabled={
                                !availableData.includes("revenue/employee")
                              }
                            >
                              Revenue / Employees
                            </MenuItem>
                            <MenuItem
                              value={"total_emission"}
                              disabled={
                                !availableData.includes("total_emission")
                              }
                            >
                              Total Emissions
                            </MenuItem>
                            <MenuItem
                              value={"scope1_emission"}
                              disabled={
                                !availableData.includes("scope1_emission")
                              }
                            >
                              Scope 1 Emissions
                            </MenuItem>
                            <MenuItem
                              value={"scope2_location"}
                              disabled={
                                !availableData.includes("scope2_location")
                              }
                            >
                              Scope 2 Location-Based Emissions
                            </MenuItem>
                            <MenuItem
                              value={"scope2_market"}
                              disabled={
                                !availableData.includes("scope2_market")
                              }
                            >
                              Scope 2 Market-Based Emissions
                            </MenuItem>
                            <MenuItem
                              value={"scope3_emission"}
                              disabled={
                                !availableData.includes("scope3_emission")
                              }
                            >
                              Scope 3 Emissions
                            </MenuItem>
                            <MenuItem
                              value={"scope_1_2_emissions"}
                              disabled={
                                !availableData.includes("scope_1_2_emissions")
                              }
                            >
                              Scope 1 & 2 Total Emissions
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <ChartHandler
                        selected={selectedChart}
                        data={emissionData}
                      />
                    </ChartWrapper>
                  )}
                </Blur>
              </Wrapper>
            </Fragment>
          ) : null}
        </Container>
      </Slide>
    </MUIModal>
  );
};

export default CompanyModal;
