import { Stack, Typography, Box, Tooltip } from "@mui/material";
import { styled } from "@mui/system";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const TooltipIcon = styled(HelpOutlineIcon)({
  opacity: 0.3,
  transition: "0.5s",
  "&:hover": {
    opacity: 0.5,
  },
});

const Wrapper = styled(Stack)(({ theme, border }) => ({
  padding: "4px 8px",
  marginRight: -1,
  borderBottom: border ? "solid 1px #ddd" : "none",
}));

const DataBlock = ({
  title,
  type,
  children,
  handleNumbers,
  helperText,
  border,
}) => {
  const handleType = (type) => {
    switch (type) {
      case "link":
        return (
          <a
            href={children}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      case "text":
        return children ? children : " - ";
      case "number":
        return children
          ? handleNumbers(
              children,
              ["Revenue ( TTM )", "EBITDA"].includes(title)
            )
          : " - ";
      default:
        return " - ";
    }
  };

  return (
    <Wrapper border={border}>
      {title && (
        <Box display="flex" alignItems="center" sx={{ gap: 0.5 }}>
          <Typography variant="subtitle1">{title}</Typography>
          {helperText && (
            <Tooltip
              arrow
              placement="top"
              title={<Typography fontSize={14}>{helperText}</Typography>}
            >
              <TooltipIcon fontSize="small" />
            </Tooltip>
          )}
        </Box>
      )}
      <Typography component="div">{handleType(type)}</Typography>
    </Wrapper>
  );
};

export default DataBlock;
