import { Fragment } from "react";

import Popup from "./popup";
import Survey from "./survey";

const SurveyPopup = ({
  open,
  handlePopup,
  companyName,
  surveyMode,
  handleSurveyMode,
  closeSurvey,
}) => {
  return (
    <Fragment>
      {!surveyMode ? (
        <Popup
          open={open}
          companyName={companyName}
          handleSurveyMode={handleSurveyMode}
          handleDrawer={handlePopup}
        />
      ) : (
        <Survey closeSurvey={closeSurvey} companyName={companyName} />
      )}
    </Fragment>
  );
};

export default SurveyPopup;
