import { Box } from "@mui/material";
import { styled } from "@mui/system";

import Card from "./card";
import NewsFeed from "./NewsFeed";

import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";

const BoxWrapper = styled(Box)({
  borderRadius: 3,
  background: "#fff",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
});

const NewsWrapper = styled(Box)({
  height: 645,
  borderRadius: 3,
  background: "#fff",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
});

const FrontPage = () => {
  return (
    <Box sx={{ background: "#ececec" }}>
      <Box
        p={4}
        sx={{
          flexWrap: "wrap",
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
        }}
      >
        <BoxWrapper sx={{ flex: 1 }}>
          <Card title="Company Search" path="/company-data" image={image1} />
        </BoxWrapper>
        <BoxWrapper sx={{ flex: 1 }}>
          <Card title="Industry Insights" path="/" image={image2} />
        </BoxWrapper>
        <BoxWrapper sx={{ flex: 1 }}>
          <Card title="Your Lists" path="/" image={image3} />
        </BoxWrapper>
        <NewsWrapper p={2}>
          <NewsFeed />
        </NewsWrapper>
      </Box>
    </Box>
  );
};

export default FrontPage;
