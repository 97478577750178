import { Box, Grid, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";

import Logo from "./logo.js";
import Description from "./description.js";
import DataBlock from "./dataBlock.js";

const GridContainer = styled(Grid)({
  border: "solid 1px #ddd",
  borderRadius: 5,
  backgroundColor: "#fff",
});

const Wrapper = styled(Box)({
  flexWrap: "nowrap",
  "& > *": {
    borderTop: "solid 1px #ddd",
    marginTop: -1,
  },
});

const MultiBlock = styled(Box)({
  display: "flex",
  "& > *:not(:last-of-type)": {
    borderRight: "solid 1px #ddd",
  },
  "& > *": {
    flex: 1,
    borderRight: "none",
  },
});

const CompanyFundamentals = ({ data, handleNumbers }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <GridContainer container sx={{ margin: 0 }}>
      <Grid
        item
        sm={12}
        md={6}
        sx={{ borderRight: matches ? "none" : "solid 1px #ddd" }}
      >
        <Box>
          <Logo src={`https://eodhistoricaldata.com${data.logo_url}`} />
        </Box>
        <Wrapper>
          <MultiBlock>
            <DataBlock title="Sector" type="text">
              {data.sector}
            </DataBlock>
            <DataBlock title="Industry" type="text">
              {data.industry}
            </DataBlock>
          </MultiBlock>

          <MultiBlock>
            <DataBlock title="Exchange" type="text">
              {data.exchange}
            </DataBlock>
            <DataBlock title="Code" type="text">
              {data.code}
            </DataBlock>

            <DataBlock title="ISIN" type="text">
              {data.ISIN}
            </DataBlock>
          </MultiBlock>

          <DataBlock title="Description" type="text">
            <Description>{data.description}</Description>
          </DataBlock>
        </Wrapper>
      </Grid>
      <Grid item sm={12} md={6}>
        <Wrapper>
          <MultiBlock>
            <DataBlock title="Country" type="text">
              {data.country_name}
            </DataBlock>
            <DataBlock title="Currency" type="text">
              {data.currency_code}
            </DataBlock>
          </MultiBlock>

          <DataBlock title="Website" type="link">
            {data.web_url}
          </DataBlock>

          <DataBlock
            title="Employees"
            type="text"
            handleNumbers={handleNumbers}
          >
            {data.fulltime_employees}
          </DataBlock>
          <DataBlock
            title="Market Capitalization"
            type="number"
            handleNumbers={handleNumbers}
          >
            {data.market_capitalization_mln}
          </DataBlock>
          <DataBlock
            title="EBITDA"
            type="number"
            handleNumbers={handleNumbers}
            helperText="Earnings before interest, taxes, depreciation, and amortization"
          >
            {data.EBITDA}
          </DataBlock>
          <DataBlock
            title="Revenue ( TTM )"
            type="number"
            handleNumbers={handleNumbers}
            helperText="TTM = Trailing Twelve Months"
            border={!matches}
          >
            {data.revenue_ttm}
          </DataBlock>
        </Wrapper>
      </Grid>
    </GridContainer>
  );
};

export default CompanyFundamentals;
