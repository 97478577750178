import React, { useState, useEffect } from "react";
import MaterialTable from "components/Material-Table";
import { Box, Button } from "@mui/material";
import { removeDuplicates } from "utils/removeDuplicates";
import PlaceholderLogo from "images/logo-placeholder.png";
import SortedCompanies from "./sortedCompanies.json";

import CompanyModal from "./CompanyModal";

import axios from "axios";

const sortByArray = (array) => {
  const tmp = array.filter((e) => SortedCompanies.includes(e.ISIN));
  const newArray = [
    ...tmp,
    ...array.filter((e) => !SortedCompanies.includes(e.ISIN)),
  ];
  return newArray;
};

const CompanyData = ({ API }) => {
  const [companyData, setCompanyData] = useState(null);
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [companyModal, setCompanyModal] = useState(false);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const result = await axios.get(API + "/getFundamentalData");
        if (result && result.status === 200) {
          return result.data;
        }
        return [];
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    (async () => {
      const companies = await fetchCompanies();
      const sortedArray = sortByArray(companies);
      setCompanyData(sortedArray);
    })();

    return () => {
      setCompanyData(null);
      setSelectedCompanyData(null);
    };
  }, [API]);

  const handleCompanyModal = (e) => {
    setCompanyModal((prev) => !prev);
  };

  const handleRowClick = (e, row) => {
    e.stopPropagation();
    setSelectedCompanyData(row);
    handleCompanyModal();
  };

  /* const rowHasData = (data) => {
    if (!data || !companyData) return true;
    const tmp = companyData.filter((e) => e.company_name === data.company_name);
    if (tmp.length === 0) return true;
    return tmp.some((e) => e.emission_year === null);
  }; */

  const handleNumbers = (number, convert) => {
    if (!number) return " - ";
    if (convert) number = number / 1000000; // Convert to millions
    return `${parseFloat(number.toFixed(1))}M`;
    /* if (number < 1000) return `${number.toFixed(1)}M`;
    return `${(number / 1000).toFixed(1)}B`; */
  };

  const handleLongName = (string) => {
    if (!string) return " - ";
    return string.length > 20 ? string.substring(0, 20) + "..." : string;
  };

  const handleLogo = (data) => {
    return (
      <Box display="flex" justifyContent="center">
        <img
          style={{ width: 100 }}
          src={`https://eodhistoricaldata.com${data.logo_url}`}
          alt="logo"
          onError={(e) => (e.target.src = PlaceholderLogo)}
        />
      </Box>
    );
  };

  return (
    <Box>
      <CompanyModal
        open={companyModal}
        handleModal={handleCompanyModal}
        data={selectedCompanyData}
        handleNumbers={handleNumbers}
        API={API}
      />
      <MaterialTable
        title=""
        columns={[
          {
            title: "ID",
            field: "id",
            hidden: true,
          },
          {
            title: "",
            sorting: false,
            render: (rowData) => handleLogo(rowData),
          },
          {
            title: "Name",
            field: "name",
            render: (rowData) => handleLongName(rowData.name),
          },
          {
            title: "ISIN",
            field: "ISIN",
            render: (rowData) => rowData.ISIN ?? " - ",
          },
          {
            title: "Country",
            field: "country",
            render: (rowData) => rowData.country ?? " - ",
          },
          {
            title: "Market Capitalization",
            field: "market_capitalization_mln",
            render: (rowData) =>
              handleNumbers(rowData.market_capitalization_mln, false),
          },
          {
            title: "Revenue ( TTM )",
            field: "revenue_ttm",
            render: (rowData) => handleNumbers(rowData.revenue_ttm, true),
          },
          {
            title: "",
            sorting: false,
            render: (rowData) => (
              <Button
                color="button"
                variant="contained"
                size="small"
                //disabled={rowHasData(rowData)}
                onClick={(e) => handleRowClick(e, rowData)}
              >
                Open
              </Button>
            ),
          },
        ]}
        data={removeDuplicates(companyData, "ISIN")}
      />
    </Box>
  );
};

export default CompanyData;
