import { Box } from "@mui/material";
import PlaceholderLogo from "images/logo-placeholder.png";

const Logo = ({ src }) => {
  return (
    <Box
      p={3}
      display="flex"
      justifyContent="center"
      sx={{ borderBottom: "solid 1px #ddd" }}
    >
      <img
        width="30%"
        src={src}
        alt="logo"
        onError={(e) => (e.target.src = PlaceholderLogo)}
      />
    </Box>
  );
};

export default Logo;
