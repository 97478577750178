import ReactApexChart from "react-apexcharts";

export default function RealApexChart({
  height,
  type,
  options,
  series,
  ...other
}) {
  return (
    <ReactApexChart
      height={height}
      type={type}
      options={options}
      series={series}
      {...other}
    />
  );
}
