import ApexCharts from "components/ApexCharts";
import { useTheme } from "@mui/material";

const chartStyle = {
  height: 350,
  width: "100%",
};

const EmissionChart = ({ data, title = "" }) => {
  const theme = useTheme();
  return (
    <div style={chartStyle}>
      <ApexCharts
        type={"line"}
        series={[
          {
            name: "Total Emissions",
            type: "column",
            data: data ? data.map((e) => e.total_emissions) : [],
          },
        ]}
        height={"100%"}
        options={{
          colors: [theme.palette.chart.bar],
          chart: {
            type: "line",
            width: "100%",
            toolbar: {
              show: false,
            },
          },
          legend: {
            position: "top",
            showForSingleSeries: true,
          },
          tooltip: {
            y: {
              formatter: (value, opts) => {
                return value.toFixed(2) + " t CO2e";
              },
            },
          },
          title: {
            text: title,
            align: "center",
            style: {
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "subheader",
            },
          },
          labels: data ? data.map((e) => e.emission_year) : [],
        }}
      />
    </div>
  );
};

export default EmissionChart;
